import React from "react";
import { Page, Document, StyleSheet, View, Text } from "@react-pdf/renderer";


const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    backgroundColor: "#f0f0f0",
    padding: 40,
  },
  header: {
    fontSize: 36,
    marginBottom: 20,
    fontWeight: "bold",
    textAlign: "center",
    color: "#333333",
    backgroundColor: "#1861e0",
    padding: 12,
    borderRadius: 8,
    textShadow: "2px 2px 4px #999999",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 30,
  },
  table: {
    width: "100%",
    border: "1 solid #333333",
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "#ffffff",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#1861e0",
    color: "#ffffff",
    padding: 12,
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#f5f5f5",
    color: "#333333",
    padding: 12,
  },
  tableCell: {
    flex: 1,
    fontSize: 14,
    textAlign: "center",
    padding: 12,
  },
  label: {
    fontWeight: "bold",
    textAlign: "center",
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    color: "#888888",
  },
});

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", {
    month: "short",
    timeZone: "UTC",
  });
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${month} ${day}, ${year}`;
};

const StudentPDF = ({ student }) => {
  const formattedBirthDate = formatDate(student.birthDate);

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Text>Student Information</Text>
        </View>
        <View style={styles.infoContainer}>
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableHeader}>
              <Text style={[styles.tableCell, styles.label]}>First Name</Text>
              <Text style={[styles.tableCell, styles.label]}>Last Name</Text>
              <Text style={[styles.tableCell, styles.label]}>Birthdate</Text>
              <Text style={[styles.tableCell, styles.label]}>Gender</Text>
              <Text style={[styles.tableCell, styles.label]}>Grade to Be</Text>
            </View>

            {/* Table Rows */}
            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>{student.firstName}</Text>
              <Text style={styles.tableCell}>{student.lastName}</Text>
              <Text style={styles.tableCell}>{formattedBirthDate}</Text>
              <Text style={styles.tableCell}>{student.gender}</Text>
              <Text style={styles.tableCell}>{student.gradeToBe}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.footer}>
          Generated by Ha Geiz - {new Date().toLocaleDateString()}
        </Text>
      </Page>
    </Document>
  );
};

export default StudentPDF;
